import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "order-pickup"
    }}>{`Order pickup`}</h1>
    <h3 {...{
      "id": "roughly-divided-one-can-say-that-order-pickup-is-done-in-three-different-ways"
    }}>{`Roughly divided, one can say that order pickup is done in three different ways:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A NOD Client retrieves first and best OrderGroup on the card number (PDO Scenario)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A NOD Client retrieves a specific OrderGroup that has just been sold by the same application (TVM Scenario)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A NOD Client obtains a Static Order to implement a specific alternative scenario.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A NOD client retrieves all orders assigned to the client's ID (POS).`}</p>
      </li>
    </ul>
    <p>{`Ruters Mobile APP today is the TVM scenario. That is, the client sells a ticket through an internal sales solution and picks it up immediately. In this case, the sales solution must tell the NOD Client which order group it should pick up.`}</p>
    <p>{`In the future one can imagine a solution where parents can buy a ticket in a WEB-based sales solution that is then retrieved on the mobile phone of a child. This can then follow the PDO scenario, where a NOD client searches for tickets that are posted via other channels.`}</p>
    <p>{`A Static Order Group is an Order Group with a unique name that a NOD Client can retrieve on their own initiative to perform special actions. A Static Order is not an order placed by a sales system in advance, but a predefined order that is always available in NOD.`}</p>
    <h3 {...{
      "id": "the-following-static-orders-are-currently-supported-on-the-nod-platform"
    }}>{`The following Static Orders are currently supported on the NOD Platform`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`staticDesfireContents - Reads by Image on Travel Card and returns a Logical representation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticNothingToDo - Returns a custom message that there is no OrderGroup to retrieve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticDesfireIndependentImg - Returns the media's independent content`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticDesfireInspection - Performs inspection on a Desfire card`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticDesfireValidation - Performs validation on a Desfire card`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticQRIndependentImg - Returns the media's independent content`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticQRInspection - Performs inspection on QR ticket`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticULIndependentImg - Returns the media's independent content`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticULInspection - Performs inspection on the UL card`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`staticULValidation - Performs validation on the UL card`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      